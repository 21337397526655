.custom-select {
  .font(@alfa-color @font-base 56px);

  border: 1px solid transparent;
  box-sizing: border-box;
  position: relative;

  &.error {
    border-color: @danger;
  }
}

.custom-select__selected {
  padding: 0 36px;
  box-sizing: border-box;
  background-color: @between-color;

  .xs({
    padding: 0 15px;
  });
}

.custom-select__selection {
  position: absolute;
  top: 100%;
  left: 6px;
  right: 6px;
  z-index: 1;
  height: ~"max(200px,100%)";
  overflow: hidden;
  background-color: @thin-color;
}

.custom-select__options {
  padding: 0 30px;
  margin-right: 12px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: @beta-color;

  &:hover, &.active {
    color: @thin-color
  }

  &:hover {
    background-color: fade(@black-darker, 30%);
  }

  &.active {
    background-color: @brand-color;
  }
}

