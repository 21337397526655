.button--primary {
  .font(@white-lighter @font-large 57px 600, {
    text-align: center;
    text-transform: uppercase;
  });

  padding: 0 52px;
  background-color: @brand-color;
  display: inline-block;
  min-width: 194px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: @transition;

  &:hover{
    background-color: @danger;
  }
}

