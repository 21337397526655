.partners {
  h2 {
    text-align: center;
    margin-bottom: 60px;
  }

  .swiper-container {
  }

  .swiper-button-next, .swiper-button-prev {
    color: @white-lighter;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: @brand-color;
    &:after {
      font-size: 31px;
      font-weight: 700;
    }
  }
}

.partners__item {
  height: 300px;
  display: flex;
  align-items: center;

  a {
    height: 100%;
    display: flex;
    align-items: center;
  }
}

.partners__text {
  .font(@brand-color 16px 24px 600);
  margin-top: 60px;
}
