.custom-switch {
  label {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  input {
    display: none;
  }
}

.custom-switch__to {
  position: absolute;
  top: 2px;
  left: 0;
  width: 17px;
  height: 17px;
  border: 2px solid @brand-color;
  box-sizing: border-box;
  text-align: center;

  &:before {
    content: " ";
    opacity: 0;
    display: inline-block;
    vertical-align: middle;
  }
}

.custom-switch__label {
  margin-left: 10px;
}

