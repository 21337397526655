.user {

  h3 {
    margin-bottom: 19px;
    word-break: break-word;
  }
}

.user__main {
  display: flex;
  flex-wrap: wrap;
}

.user__thumbnail, .user__data {
  margin-bottom: 50px;
}

.user__thumbnail {
  width: 485px;

  .sm({
    width: 100%;
  });

  img {
    width: ~"min(377px, 100%)";
    height: 357px;
    display: block;
    background-color: @between-color;
    object-fit: cover;
    object-position: top center;
    box-shadow: 0 4px 4px fade(@black-darker, 20%);
    margin-bottom: 30px;
  }

  button {
    .font(@brand-color @font-large 23px, {
      letter-spacing: 1.5px;
    });

    position: relative;
    padding-left: 40px;
    background-color: transparent;

    &.active {
      color: @danger;
    }

    span {
      position: absolute;
      top: 0;
      left: 0;
    }

  }
}

.user__data {
  width: calc(100% - 485px);

  .sm({
    width: 100%;
  });
}

.user__data-field {
  .last(not, {
    margin-bottom: 25px;
  });

  > div {
    .font(@beta-color 20px 24px 500, {
      word-break: break-word;
    });
  }

  .select--primary {
    --ls-alfa-color: @thin-color;
  }

  label {
    .font(@beta-color @font-base 20px 500);
  }
}

.user__progress {
  width: 100%;
  text-align: center;

  h4 {
    margin-bottom: 30px;
  }
}
