.fontFace('../../../fonts/montserrat/regular', 'Montserrat-Regular', 'Montserrat', 400);
.fontFace('../../../fonts/montserrat/medium', 'Montserrat-Medium', 'Montserrat', 500);
.fontFace('../../../fonts/montserrat/semi-bold', 'Montserrat-SemiBold', 'Montserrat', 600);
.fontFace('../../../fonts/montserrat/bold', 'Montserrat-Bold', 'Montserrat', 700);

.typography(true, {
  body {
    .font(@alfa-color 0 1 400, {
      font-family: @brand-family
    });
  }

  a {
    text-decoration: none;
    transition: @transition;
    outline: 0;
  }

  b {
    font-weight: 600;
  }

  h1 {
    font-size: 47px;
  }

  h2 {
    font-size: @h-font-base;
  }

  h3 {
    font-size: 36px;
  }

  h4 {
    font-size: @h-font-small;
  }

  h5 {
    font-size: 28px;
  }

  h1, h4 {
    font-weight: 500;
    line-height: 1.21;
  }

  h2, h3, h5, h6 {
    font-weight: 600;
    line-height: 1.32;
  }

  button {
    box-sizing: border-box;
    border-radius: 4px;
    cursor: pointer;
    transform: @transition;
  }

  ul {
    li {
      .font(@alfa-color @font-large 34px 600);

      padding-left: 15px;
    }
  }

  input:not([type="checkbox"]):not([type="radio"]) {
    position: relative;
    border-radius: 0;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    font-family: @brand-family;
    outline: none;
  }

  textarea {
    outline: none;
    padding: 0;
    border: 0;
    &:focus, &:valid {
      ~ label:last-child {
        display: none;
      }
    }
  }

  video {
    background-color: @beta-color;
    outline: none;
  }

  section {
    width: 100%;
  }

  [contenteditable] {
    -webkit-user-select: auto;
    user-select: all;
  }
});

