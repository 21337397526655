.info {
  text-align: center;
  margin: 66px 0;

  img {

  }

  p {
    .font(@beta-color 24px 29px 500);

    flex: 1 1 calc(100% - 193px);
    margin-bottom: 20px;

  }

  .button--primary {
    margin-top: -21px;
    width: ~"min(380px, 100%)";
  }
}

.info__main {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: @between-color;
  padding: 30px 47px 10px;
  text-align: left;
}

.info__thumbnail {
  flex: 1 1 193px;
  margin-bottom: 20px;
}
