.portal {
  display: inline-block;
  vertical-align: middle;
  max-width: calc(100% - 15px);

  .local {
    font-size: @font-base;
  }
}

.portal__container {
  background-color: @thin-color;
  padding: 60px 34px;
  border: 1px solid @meta-color;
  box-shadow: 0 4px 4px rgba(@black-darker, 25%);
  max-width: 258px;
  box-sizing: border-box;
  text-align: center;
  margin: 30px 0;
  position: relative;

  .animate({
    .action("fade-in");
  });

  .local__item {
    cursor: pointer;
  }
}

.portal__data {
  h4 {
    .font(@brand-color 20px 27px, {
      text-align: center;
    })
  }

  > a {
    .font(@beta-color @font-less 14px);
    .last({
      &:before {
        content: "|";
        margin: 0 10px;
        color: fade(@black-darker, 20%);
      }
    });
    &:hover {
      color: @brand-color
    }
  }

  p {
    .font(@alfa-color @font-base 16px);

  }

  > div, h4 {
    .last(not, {
      margin-bottom: 30px;
    });
  }

}

.portal__close {
  position: absolute;
  top: 16px;
  right: 14px;
  transform: scale(0.8);
}

.portal__container--error {
  width: 400px;
  max-width: 100%;
}

.portal__container--box {
  width: 50vw;
  max-width: 100%;
  position: relative;
  height: 50vh;
}

.portal__container--box.expandable {
  //min-height: 50vh !important;
  height: unset;
}
