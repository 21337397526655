.curators {
  padding: 89px 0 117px;
  background: @app-side-bg-reverse;
  text-align: center;

  h2 {
    .font(@thin-color, {
      text-transform: uppercase;
    });

    margin-bottom: 85px;
  }

}

.curators__main {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  grid-gap: 30px;
  text-align: left;
  margin-bottom: 60px;
}

.curators__main-item {
  position: relative;

  &.text {
    @media (min-width: 921px) {
      grid-column-start: 2;
      grid-column-end: -1;
    }
    @media(max-width: 920px) {
      display: none !important;
    }
  }

  &.last {
    @media(min-width: 921px) {
      p {
        display: none !important;
      }
    }
  }
}

.curator {
  height: 100%;
  width: 100%;

  img {
    width: 100%;
    object-fit: cover;
    height: 244px;
    margin-bottom: 15px;
  }

  p {
    .font(@thin-color 14px 18px)
  }
}
