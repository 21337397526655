.reset();
//@import "../components/ui/preloader";

html:not([theme]) {
  .defaultTheme();
}

body {
  .md({
    &.active {
      overflow: hidden;
    }
  });
}

.container {
  .container();
}

.timer {
  .font(@beta-color 16px 1);
  padding: 35px 30px 19px;
  background: #ffffff;
  border-radius: 4px;
  margin: 40px auto 0;
  border: 1px solid #E6E2E2;
  //box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.12);

  > label {
    color: @brand-color;
    font-weight: 600;

    &:first-child {
      font-size: 24px;
      line-height: 29px;
      text-transform: uppercase;
    }
    &:last-child {
      font-size: 18px;
      line-height: 22px;
    }
  }

  .timer__counter {
    display: flex;
    justify-content: center;
    margin: 20px 0 26px;
  }

  .timer__number {
    width: 131px;
    height: 104px;
    background: #ffffff;
    box-shadow: 0 0 10px rgba(183, 183, 183, 0.25);
    position: relative;

    span {
      font-size: 12px;
      line-height: 15px;
      color: @brand-color;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .timer__count {
    font-weight: 600;
    font-size: 64px;
    color: @brand-color;
    line-height: 100px;
  }

  .timer__dot {
    font-weight: 600;
    font-size: 64px;
    color: @brand-color;
    line-height: 94px;
    margin: 0 12px;
  }
}

//.debug();

.my-3 {
  margin: 1rem auto;
}

.mx-2 {
  margin: auto .75rem;
}

.textarea {
  border: 1px solid grey;
  padding: .5rem 1rem;
  resize: vertical;
}

.button--small {
  padding: .75rem 1rem !important;
  line-height: 1rem !important;
  min-width: 100px !important;
}

.text-align-right {
  text-align: right !important;
}