.sign-up {

  .date {

  }

  .auth__entry {
    margin-top: 36px;
    text-align: center;

    .switch--primary {
      .first(type, {
        margin: 0 20px 0 auto;
      });
    }

    &-training {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 75px;
      text-align: left;

      .font(@alfa-color @font-large 17px 600);

      &:not(:first-child):not(:last-child) {
        .switch--primary {
          margin: 0;
        }
      }
    }
  }
}

@bgcolor: #000;
@transparent: rgba(255, 255, 255, 0);

@color-1: white;

@size: 7px;
@big-size: @size*2;
@min: 0;
@max: @size*.3;

.sign-up-close {
  background-color: @brand-color;
  max-width: 1280px;
  margin: auto;
  width: 100%;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 85px;
    height: 155px;
    width: 241px;
    background: radial-gradient(circle, @color-1 @max, @transparent @max*1.3) 0 0,
      radial-gradient(circle, @color-1 @max, @transparent @max*1.3) @size @size;
    background-size: @big-size @big-size;
    background-color: @brand-color;
    background-repeat: repeat;
    background-position: top left;
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 5px;
    right: 85px;
    height: 155px;
    width: 241px;
    background: radial-gradient(circle, @color-1 @max, @transparent @max*1.3) 0 0,
      radial-gradient(circle, @color-1 @max, @transparent @max*1.3) @size @size;
    background-size: @big-size @big-size;
    background-color: @brand-color;
    background-repeat: repeat;
    background-position: top left;
  }
}

.sign-up-close__main {
  padding: 181px 64px 90px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .md({
    padding: 181px 30px 181px;
  });
}

.sign-up-close__left {
  background-color: #fff;
  padding: 57px 61px;
  width: 516px;
  box-sizing: border-box;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  z-index: 1;

  .md({
    width: 100%;
    margin-bottom: 40px;
    padding: 40px;
  });

  h1 {
    font-size: 28px;
    color: @brand-color;
    margin-bottom: 46px;
  }

  p {
    font-size: 19px;
    color: #030706;
    font-weight: 500;
    line-height: 23px;
    max-width: 90%;

    &:first-of-type {
      margin-bottom: 73px;
    }
  }
}

.sign-up-close__right {
  width: calc(100% - 516px);
  padding: 90px 10px 90px 97px;
  box-sizing: border-box;
  border-radius: 30px;
  background: #fff;
  position: relative;

  > div,
  > label {
    position: relative;
  }

  &:before,
  &:after {
    content: '';
    position: absolute;

    .md({
      content: none
    });
  }

  &:before {
    background: url('../../../../../../../images/general/lar.png') center right / cover no-repeat;
    width: 100px;
    height: 300px;
    left: -20px;
    top: -16px;
  }

  &:after {
    position: absolute;
    bottom: 5px;
    right: 85px;
    height: 155px;
    width: 241px;
    background: radial-gradient(circle, @color-1 @max, @transparent @max*1.3) 0 0,
      radial-gradient(circle, @color-1 @max, @transparent @max*1.3) @size @size;
    background-size: @big-size @big-size;
    background-color: transparent;
    background-repeat: repeat;
    background-position: top left;
    left: 0;
    top: 100%;
    transform: translate(-50%, -50%);
  }

  .md({
    width: 100%;
    padding: 40px;
  });

  label {
    font-size: 16px;
    margin-bottom: 15px;
    display: inline-block;
    margin-left: 5px;
  }
}

.sign-up-close__form {
  display: flex;
  align-items: center;

  .xs({
    flex-wrap: wrap;
  });

  div {
    flex: auto;

    .xs({
      width: 100%;
      margin-bottom: 30px;
    });
  }

  input {
    height: 40px;
  }

  button {
    margin: 0 30px 0 21px;
    background-color: @brand-color;
    font-size: 16px;
    padding: 0 20px;
    line-height: 30px;
    border-radius: 4px;
    color: #fff;

    .xs({
      margin: 0;
    });
  }
}
