.mobileNavigation() {
  position: fixed;
  right: 100%;
  bottom: 0;
  z-index: 1;
  width: 100%;
  background-image: @app-side-bg;
  padding: 15px;
  box-sizing: border-box;
  height: calc(100% - 67px);
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  transition: transform .3s ease;

  &.active {
    transform: translateX(100%);
  }
}

.buttonToggle(@end: {}) {
  @end();
  position: relative;
  right: 0;
  width: 40px;
  height: 40px;
  margin-left: auto;
  cursor: pointer;
  background-color: transparent;

  &:before, &:after {
    content: "  ";
    background-color: @alfa-color;
    width: 100%;
    height: 4px;
    position: absolute;
    transition: @transition;
    border-radius: 10px;
    left: 0;
  }

  &:before {
    top: 12px;
  }

  &:after {
    bottom: 12px;
  }

  &:hover, &.active {
    &:before {
      transform: translate3d(0, 5px, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(0, -7px, 0) rotate(-45deg);
    }
  }
}

.mobile(@end: {}) {
  @end();
  position: relative;
  right: 0;
  width: 46px;
  height: 46px;
  margin-right: auto;
  cursor: pointer;
  background-color: transparent;

  &:before, &:after {
    content: "  ";
    background-color: @thin-color;
    width: 100%;
    height: 6px;
    position: absolute;
    transition: @transition;
    left: 0;
  }

  &:before {
    top: 8px;
  }

  &:after {
    bottom: 8px;
  }

  &.active {
    &:before {
      transform: translate3d(0, 9px, 0) rotate(45deg);
    }

    &:after {
      transform: translate3d(0, -15px, 0) rotate(-45deg);
    }

    span{
      opacity: 0;
    }
  }

  span {
    background-color: @brand-color;
    width: 100%;
    height: 6px;
    position: absolute;
    transition: @transition;
    top: 20px;
    left: 0;
  }
}

.preloaderImage(){
  &__spinner {
    position: absolute;
    left: 50%;
    width: 50vw;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
}
