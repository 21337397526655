@store: {
  .animate({
    .is("slide-up", {
      0% {
        transform: translateY(-100%);
      }
      100% {
        transform: translateY(0);
      }
    });
    .is("fade-in", {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    });
    .is('pulse', {
      @shadowPulse: @primary;
      0% {
        box-shadow: 0 0 0 0 rgba(@shadowPulse, 0.2),
        0 0 0 6px rgba(@shadowPulse, 0.2),
        0 0 0 12px rgba(@shadowPulse, 0.2),
        0 0 0 27px rgba(@shadowPulse, 0.2);
      }
      100% {
        box-shadow: 0 0 0 6px rgba(@shadowPulse, 0),
        0 0 0 12px rgba(@shadowPulse, 0),
        0 0 0 27px rgba(@shadowPulse, 0),
        0 0 0 48px rgba(@shadowPulse, 0);
      }
    });
    .is('tadams', {
      from {
        transform: scale(1, 1);
      }
      30% {
        transform: scale(1.25, 0.75);
      }
      40% {
        transform: scale(0.75, 1.25);
      }
      50% {
        transform: scale(1.15, 0.85);
      }
      65% {
        transform: scale(0.95, 1.05);
      }
      75% {
        transform: scale(1.05, 0.95);
      }
      to {
        transform: scale(1, 1);
      }
    });
  });
}

.animate({
  .model(
    @types:
    "pulse"
    "slide-up"
    "fade-in",
    @store: @store
  );
});
