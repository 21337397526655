.about {
  .font(@alfa-color @font-large 29px 600);
  padding: @home-about-pg;

  h2, h3  {
    color: @brand-color;
    text-transform: uppercase;
    margin-bottom: 38px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 28px;
  }

  ul {
    margin-left: 10px;
  }

  p {
    max-width: 540px;
  }

  .button--primary {
    margin-top: 45px;
  }
}

.about__head {
  margin: @home-about-offset;

  //.md(min, {
  //  width: ~"min(520px, 100%)";
  //  position: relative;
  //
  //  &:before {
  //    content: " ";
  //    background: url("../../../../../../../images/general/basketball-player-min.png") center / contain no-repeat;
  //    width: 320px;
  //    height: 852px;
  //    position: absolute;
  //    right: calc(100% + 149px);
  //    top: -180px;
  //  }
  //});
}

.about__foot {

  .min(1200px, {
    width: ~"min(870px, 100%)";
    position: relative;

    h2{
      max-width: 80%;
    }

    &:before {
      content: " ";
      background: url("../../../../../../../images/general/ball-min.png") center / contain no-repeat;
      width: 493px;
      height: 477px;
      position: absolute;
      top: 8px;
      left: 100%;
      transform: translateX(-50%);
    }
  })
}

@media(min-width: 1400px) {
  .about p {
    max-width: 900px !important;
  }
  .about__foot {
    width: unset !important;
  }
}
