.worksheet__title{
  text-transform: uppercase;
  color: @brand-color;
  text-align: center;
  margin-bottom: 48px;
  font-weight: 600;
}

.worksheet__block {
  background-color: @thin-color;
  padding: 47px 10px;
  border: 1px solid @meta-color;
  box-sizing: border-box;

  .max(540px, {
    padding-right: 0;
    padding-left: 0;
  });

  .last(not, {
    margin-bottom: 20px;
  });

  h4 {
    text-transform: uppercase;
    color: @brand-color;
    text-align: center;
    margin-bottom: 48px;
  }
}

.worksheet__block-row {
  width: ~"min(800px, 100%)";
  margin: 0 auto;

  .last(not, {
    margin-bottom: 50px;
  });

  .button--load{
    margin-top: 14px;
    display: inline-block;
  }

  .select--born {
    .custom-select {
      --ls-font-base: 11px;
      line-height: 15px;
      &:before {
        content: none;
      }
    }
    .custom-select__selected, .custom-select__options  {
      padding: 2px;
    }
    .custom-select__selection{
      min-width: 100px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}
