.result {
  padding-top: 99px;
  text-align: center;

  .xs({
    h1 {
      font-size: 30px;
    }
  });

  h2 {
    .font(@brand-color, {
      text-transform: uppercase;
      text-align: center;
    });

    margin-bottom: 97px;
  }

  .certificate {
    margin: 0 auto 100px;
  }

  > div {
    > p {
      .font(@alfa-color 14px 18px 500);

      margin-bottom: 45px;
    }
  }
}
