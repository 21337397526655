.progress--primary {
  .progress-bar {
    width: ~"min(690px, 100%)";
    height: 33px;
    background-color: @between-color;
    margin: 0 auto;
    position: relative;
  }

  .progress-bar__value {
    .font(@thin-color 24px 33px 500, {
      text-align: right;
    });

    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: @brand-color;
    padding-right: 6px;
    box-sizing: border-box;
    transition: @transition;
  }
}
