.upload-files {


  input {
    display: none;
  }

  label {

  }

  span {
    vertical-align: middle;
    cursor: pointer;
  }

  span:not([class]) {
    .font(@brand-color @font-small 17px 600);

    margin-left: 17px;
  }

  span[class] {
    display: inline-block;
  }
}

.upload-files__added {
  .font(@brand-color @font-small 17px 600, {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  });

  max-width: 300px;
  cursor: pointer;

  span{
    margin-right: 17px;
  }

  &:hover {
    --ls-brand-color: @danger;
    text-decoration: line-through;
  }
}
