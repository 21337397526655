.input--transparent {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  text-align: center;

  input {
    .font(@beta-color @font-small 16px, {
      text-align: center;
    });

    background-color: transparent;
    border: 0;
    height: 16px;
  }

  label {
    .font(@alfa-color @font-large 22px 500);

    margin-bottom: 5px;
  }
}
