.icon--add {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-add-min.png") center / contain no-repeat;
}

.icon--area {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-area-min.png") center / contain no-repeat;
}

.icon--load {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-load-min.png") center / contain no-repeat;
}

.icon--blocked {
  width: 16px;
  height: 16px;
  background: url("../../../icons/icon-blocked-min.png") center / contain no-repeat;
}

.icon--burger {
  width: 18px;
  height: 16px;
  background: url("../../../icons/icon-burger-min.png") center / contain no-repeat;
}

.icon--edit {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-edit-min.png") center / contain no-repeat;
}

.icon--instagram {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-instagram-min.png") center / contain no-repeat;
}

.icon--link {
  width: 23px;
  height: 23px;
  background: url("../../../icons/icon-link-min.png") center / contain no-repeat;
}

.icon--player {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-player-min.png") center / contain no-repeat;
}

.icon--profile {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-profile-min.png") center / contain no-repeat;
}

.icon--search {
  width: 17px;
  height: 17px;
  background: url("../../../icons/icon-search-min.png") center / contain no-repeat;
}

.icon--telegram {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-telegram-min.png") center / contain no-repeat;
}

.icon--user {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-user-min.png") center / contain no-repeat;
}

.icon--warn {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-warn-min.png") center / contain no-repeat;
}

.icon--chat {
  width: 20px;
  height: 20px;
  background: url("../../../icons/icon-chat-min.png") center / contain no-repeat;
}

.icon--arrow-right{
  width: 34px;
  height: 34px;
  background: url("../../../icons/ArrowRight.png") center / contain no-repeat;
}

.icon--arrow-left{
  width: 17px;
  height: 17px;
  background: url("../../../icons/ArrowLeft.png") center / contain no-repeat;
}
