.homework {
  text-align: center;

  h2 {
    font-size: 30px;
    line-height: 37px;
    font-weight: 700;
    margin: 57px 0 54px;
  }

  h4 {
    text-align: center;
    color: @brand-color;
    text-transform: uppercase;
    font-weight: 600;
  }

  .button--primary {
    font-size: 15px;
    line-height: 61px;
    min-width: 140px;
    padding: 0 20px;

    .icon--arrow-left {
      margin-right: 15px;
    }

    .icon--arrow-right {
      margin-left: 15px;
    }

    span {
      display: inline-block;
      vertical-align: middle;
      width: 17px;
    }
  }
}

.homework__block {
  background-color: @thin-color;
  padding: 47px 52px;
  border: 1px solid @meta-color;
  box-sizing: border-box;

  .sm({
    padding: 47px 15px;
  });

  .last(not, {
    margin-bottom: 48px;
  });

  h4 {
    --ls-brand-color: @beta-color;
  }

  .button--primary {
    min-width: 228px;

    &.inactive {
      filter: grayscale(100%);
      pointer-events: none;
    }
  }
}

.homework__main {
  ~ a {
    display: inline-block;
    margin: 40px 15px 0;

    &.inactive {
      pointer-events: none;

      > div {
        color: #ffffff;
        background-color: #666666;
      }
    }
  }
}

.homework__block-main {
  width: ~"min(799px, 100%)";
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  .last(not, {
    margin-bottom: 49px;
  });

  .question {
    margin-bottom: 49px;
  }

  .question, .admin-comment {
    width: 100%;
  }

  .upload-files {
    margin: 0 auto;
  }

  .admin-comment {
    margin-top: 49px;
  }
}

.homework__answer {
  .font(@brand-color @font-small 17px 500);

  display: grid;
  grid-gap: 32px;
  flex: 1 1 auto;
  margin-bottom: 50px;
  width: 100%;

  &.inactive {
    pointer-events: none;

    + div {
      display: block;
    }
  }

  .switch--primary {
    .custom-switch__label {
      text-align: left;
    }
  }
}

