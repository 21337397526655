.select--born {
  display: flex;
  flex-wrap: wrap;

  label {
    .font(@alfa-color @font-large 22px 500);

    width: 100%;
    margin-bottom: 5px;
  }

  > div {
    width: @sign-up-width-date;

    .last(not, {
      margin: @sign-up-offset-date;
    })
  }
}

