.question {
}

.question__title {
  .font(@thin-color 18px 22px 600, {
    text-transform: uppercase;
  });

  background-color: @brand-color;
  padding: 16px 15px;
  margin-bottom: 5px;

  table {
    .xs({
      font-size: 9px;
      width: auto !important;
      padding: 0 ;
      line-height: 1.8;


      td {
        width: 50%!important;
        & >* {
          margin: 0!important;
          padding: 0 10px!important;
        }
        li {
          text-align: left!important;
        }
      }
    });
  }
}

.question__data {
  .font(@beta-color @font-small 16px 600);

  min-height: 210px;
  padding: 30px;
  box-sizing: border-box;
  background-color: @between-color;
}
