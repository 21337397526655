.dropzone--primary {
  position: relative;

  input {
    display: none;
  }

  label {
    position: absolute;
    top: 0;
    left: 0;
    width: ~"min(377px, 100%)";
    height: 357px;
    cursor: pointer;
    border: 2px solid @danger;
    box-sizing: border-box;
  }
}

.dropzone--primary__thumb {

}
