.custom-player {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .player-wrapper {
    width: 100%;
  }

  .react-player__preview {
    &:focus {
      outline: none;
    }
  }
  .react-player__preview {
    img{
      max-height: 135px;
    }
  }
}

.player-wrapper__icon{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  img{
    max-height: 135px;
  }
}

.custom-player--effect {
  background: @app-side-bg;

  .player-wrapper {
    transition: @transition;
  }

  &:hover {
    .player-wrapper {
      transform: translate(8px, -8px);
    }
  }
}
