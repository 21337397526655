.footer {
  display: flex;
  flex-wrap: wrap;

  h6 {
    .font(@thin-color @font-base 18px);

    margin-bottom: 20px;
  }
}

.footer__main-item {
  margin-bottom: 30px;
}

.footer__main {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: ~"min(472px, 100%)";
  margin-right: auto;
}

.footer__link {
  .font(@thin-color @font-small 16px, {
    text-transform: capitalize;
  });

  display: block;
  margin-bottom: 20px;
}

.footer__controls {

  .controls {
    flex-direction: row-reverse;
    margin-bottom: 40px;

    &__item {

      &:not(:first-child) {
        margin-right: 15px;
      }
    }
  }
}
