.header {
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
}

.header__title {
  margin: @app-offset-title;

  .md({
    z-index: 2;
    position: relative;
  });

  img {
    max-height: @app-size-title;
  }
}

.header__main {
  display: flex;
  align-items: center;
  flex: 1 1 auto;

  .md({
    .mobileNavigation();
  });

  .controls{
    width: ~"min(250px, 100%)";

    .md(min, {
      margin-left: 45px;
      margin-right: 30px;
    });

    &__item{
      &:not(:last-child) {
        margin-right: 15px;
      }
    }
  }
}
