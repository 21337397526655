.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .md(min, {
    position: relative;
    background: url("../../../images/general/bg-general-min.jpg") top center / cover no-repeat @bg-global-color;

    .animate({
      .action("fade-in");
    });

    &:before {
      content: " ";
      background-color: @thin-color;
      opacity: .7;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    > div{
      position: relative;
    }
  });
}

.app__head {
  padding: @app-head-pg;
  background-image: @app-side-bg;
  position: relative;
  z-index: 2;
  transition: @transition;

  &.scrolled {
    position: sticky;
    top: 0;
    box-shadow: 0 -11px 25px 5px @beta-color;

    .animate({
      .action("slide-up");
    });
  }
}

.app__body {
  display: flex;
  flex-direction: column;
  margin: @app-body-offset;

  //.md({
  //  flex-wrap: wrap;
  //});
}

.app__foot {
  margin-top: auto;
  background-image: @app-side-bg;
  padding: 70px 0 40px;
}

.app__body-side {

}

.app__body-main {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  margin: 0 25px;
}

.app__block {
  .flex(column);
  background-color: fade(@white-darker, 80%);
  box-sizing: border-box;
  border-radius: 15px;
  border: 1px solid @meta-color;
  height: 100%;
  flex: 1 1 auto;
  overflow: hidden;

  &[class$="block"] {
    height: 100%;
  }
}

.app__block-head {
  .font(@thin-color @h-font-small 50px);
  padding: 0 20px;
  box-sizing: border-box;
  background-color: @brand-color;
  border-radius: 15px 15px 0 0;
}

.app__block-body {
  flex: 1 1 auto;
  overflow: hidden;
  height: 100%;
}

.app__error {
  .font(@brand-color @h-font-base 1);
  .flex.center();
  width: 100%;
  height: 100%;
}

.part{
  z-index: 2;
  position: absolute;
  right: 14px;
  top: 11px;
  width: 100px;

  .max(550px, {
    display: none;
  });
}
