.career-language__title {
  .font(@thin-color @font-large 22px 600, {
    text-transform: uppercase;
    text-align: center;
  });

  background-color: @brand-color;
  padding: 16px 15px;
  margin-bottom: 5px;
}

.career-language__data {
  display: flex;

  .xs({
    flex-wrap: wrap;
  });

  .last(not, {
    margin-bottom: 5px;
  });

  > div {
    background-color: @between-color;
  }
}

.career-language__lang {
  width: 234px;
  margin-right: 5px;
  padding: 27px;
  box-sizing: border-box;
  text-align: left;

  .xs({
    width: 100%;
    padding-bottom: 10px;
    margin: 0;
  });

  .custom-select {
    line-height: 30px;
    --ls-font-base: 12px;
    cursor: pointer;
  }

  .custom-select__selected {
    --ls-between-color: @thin-color;
    padding: 0 20px;
  }

  .icon--burger{
    position: absolute;
    top: 7px;
    right: 10px;
  }
}

.career-language__entry {
  .font(@beta-color 12px 17px 500, {
    text-align: center;
  });

  flex: 1 1 auto;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px;

  > label{
    width: 100%;
    margin-bottom: 10px;
  }
}
