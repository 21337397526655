.admin-comment {
  display: grid;
  grid-template-columns: 130px calc(100% - 173px);
  grid-gap: 43px;
  margin: 31px 0 58px;
}

.admin-comment__avatar {
  width: 108px;
  height: 96px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
}

.admin-comment__data {
  .font(@beta-color @font-large 21px, {
    text-align: left;
  });

  background-color: @meta-color;
  padding: 15px;
  box-sizing: border-box;
  min-height: 150px;
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    width: 30px;
    height: 30px;
    background-color: @meta-color;
    top: 60px;
    left: 0;
    transform: translateX(-50%) rotate(45deg);

  }
}
