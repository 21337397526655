.navigation {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: ~"max(481px, 100%)";
  margin-left: -15px;

  .md({
    flex-direction: column;
    flex: 1 1 auto;
    padding: 30px 0;
  });
}

.navigation__item {
  position: relative;
  padding: 0 15px;

  &:hover {
    .navigation__children {
      opacity: 1;
      pointer-events: all;
      transform: translateY(0);
    }
  }
}

.navigation__link {
  .font(@thin-color @font-small 40px, {
    text-align: center;
    white-space: nowrap;
    text-transform: capitalize;
  });

  display: block;
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;

  .md({
    font-size: @font-base;
    margin: auto;
  });

  &:hover, &.active {
    color: #e47028;

  }

}

.navigation__children {
  display: none;

  .sm(min, {
    display: block;
    position: absolute;
    top: 100%;
    background-color: @thin-color;
    width: 100%;
    left: 0;
    padding: 5px 0;
    opacity: 0;
    pointer-events: none;
    transition: @transition;
    transform: translateY(10px);
    box-shadow: 0 0 15px fade(#000000, 20%);

    .navigation__link {
      &:not(:hover) {
        color: @alfa-color;
      }
    }
  })
}
