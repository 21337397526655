.input--primary {
  display: flex;
  flex-direction: column-reverse;
  position: relative;
  text-align: left;

  &.error {
    input {
      border-color: @danger;
    }
  }

  input {
    .font(@thin-color @font-base 56px);
    padding: 0 36px;
    background-color: @between-color;
    border: 1px solid transparent;

    &:focus, &:valid {
      ~ span {
        display: none;
      }
    }

    .last({
      &.mask {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    });
  }

  label {
    .font(@alfa-color @font-large 22px 500);

    margin-bottom: 5px;
  }
}
