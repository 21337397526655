:root {
  //fonts:
  --ls-brand-family: 'Montserrat', sans-serif;
  --ls-font-less: 11px;
  --ls-font-small: 14px;
  --ls-font-base: 16px;
  --ls-font-large: 18px;
  --ls-h-font-small: 30px;
  --ls-h-font-base: 40px;

  //pages:
  --ls-transition: all .3s ease;
  //position:

  //media
  --ls-app-head-pg: 17px 0;
  --ls-app-side-bg: linear-gradient(to right, #004863, #005c74, #007083, #00848e, #009996);
  --ls-app-side-bg-reverse: linear-gradient(to left, #004863, #005c74, #007083, #00848e, #009996);
  --ls-app-offset-title: 8px 0 -102px;
  --ls-app-size-title: 166px;
  --ls-app-body-offset: 100px 0;
  --ls-home-about-pg: 0 0 117px;
  --ls-home-about-offset: 0 0 118px auto;
  --ls-auth-pg: 64px 55px;
  --ls-toggle-link-ps: 0;
  --ls-sign-up-offset-date: 0 10px 0 0;
  --ls-sign-up-width-date: ~"min(calc(100%/3 - (20px/3)), 100%)";
  --ls-lesson-width: repeat(auto-fit, minmax(400px, 1fr));
  //images:
}

//

.lg({
  :root {
    //fonts:

    //pages:

    //position:

    //media

    //images:
  }
});

.md({
  :root {
    //fonts:
    --ls-h-font-base: 30px;
    //pages:

    //position:

    //media
    --ls-app-offset-title: 10px 0 -31px;
    --ls-app-head-pg: 7px 0;
    --ls-app-size-title: 76px;
    --ls-home-about-pg: 0 0 100px;
    --ls-app-body-offset: 50px 0;
    --ls-home-about-offset: 0 0 60px;
    //images:
  }
});

.sm({
  :root {
    //fonts:

    //pages:

    //position:

    //media

    //images:
  }
});

.xs({
  :root {
    //fonts:

    //pages:

    //position:

    //media
    --ls-auth-pg: 64px 15px;
    --ls-toggle-link-ps: -30px;
    --ls-sign-up-offset-date: 0 0 10px;
    --ls-sign-up-width-date: 100%;
    --ls-lesson-width: repeat(auto-fit, minmax(300px, 1fr));
    //images:
  }
});

.theme(@end:{}) {
  .general(@brand-color, @accent-1-color) {
    --ls-brand-color: @brand-color;
    --ls-accent-1-color: @accent-1-color;
  }
  .backgrounds(@bg-global-color) {
    --ls-bg-global-color: @bg-global-color;
  }
  .colors(@thin-color, @meta-color, @extra-color, @between-color, @ultra-color, @alfa-color, @beta-color) {
    --ls-thin-color: @thin-color;
    --ls-meta-color: @meta-color;
    --ls-extra-color: @extra-color;
    --ls-between-color: @between-color;
    --ls-ultra-color: @ultra-color;
    --ls-alfa-color: @alfa-color;
    --ls-beta-color: @beta-color;
  }
  @end();
}
