.custom-portal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  overflow-y: auto;
  z-index: 2;
  background-color: fade(@white-lighter, 80%);

  &:before {
    content: " ";
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}
