.preloader {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: @thin-color;
  .flex.center();

  //.preloaderSpinner();
  //.preloaderText();
  .preloaderImage();
}

