.preview-sentry {
  a {
    .font(@brand-color @font-small 17px 500, {
      text-transform: uppercase;
      word-break: break-word;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
    });
  }
}

.preview-sentry__thumbnail {
  height: 192px;
  position: relative;
  margin-bottom: 16px;
}
