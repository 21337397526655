.switch--primary {

  &.false{
    .custom-switch__to{
      border-color: @danger;
    }
  }

  &.true{
    .custom-switch__to{
      border-color: #00ff2a !important;
    }
  }


  &.check {
    pointer-events: none;
  }

  &.check {
    &:not(.inactive) {
      .custom-switch__to{
        border-color: #00ff2a !important;
      }
    }
  }

  input[type="checkbox"]:checked,
  input[type="radio"]:checked {

    ~ .custom-switch__to {

      .animate({
        .action("pulse", @duration: .3s);
      });

      &:before {
        opacity: 1;
      }
    }
  }

  &.error, &.inactive {
    .custom-switch__to {
      border-color: @danger;
    }
  }
}

.switch--primary label {
  line-height: 21px;
  padding-left: 20px;
}

.switch--primary input[type="checkbox"] {
  ~ .custom-switch__to {
    line-height: 7px;

    &:before {
      width: 11px;
      height: 6px;
      display: inline-block;
      vertical-align: middle;
      border-bottom: 2px solid @brand-color;
      border-left: 2px solid @brand-color;
      transform: rotate(-54deg) skewX(-6deg) scale(.9);
    }
  }
}

.switch--primary input[type="radio"] {

  ~ .custom-switch__to {
    line-height: 11px;
    border-radius: 50%;
    background-color: @thin-color;

    &:before {
      width: 7px;
      height: 7px;
      border-radius: 50%;
      display: inline-block;
      vertical-align: middle;
      background-color: @brand-color
    }
  }

}

