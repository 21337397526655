.addition {
  h4 {
    text-align: center;
    color: @brand-color;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 47px;
  }
}

.addition__main {

}
