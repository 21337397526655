.slider {
  margin-top: -20px;
}

.slider__item {
  display: flex;
  align-items: flex-start;
  flex-direction: row-reverse;

  img{
    display: block;
    margin-left: auto;
  }
}

.slider__item-image {
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.slider__item-thumbnail {
  width: ~"min(520px, 100%)";
  margin: auto;

  .sm({
    display: none;
  });
}

.slider__item-data {
  .sm(min, {
    max-width: 50%;
    margin-left: auto;
  });

  p {
    margin: 30px 0;
  }
}
