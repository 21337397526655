@import '~simplebar/dist/simplebar.min.css';
//@import '~swiper/swiper.less';
@import './general/xnls';
@import './general/index';
@import './components/ui/index';
@import './components/templates/index';
@import './components/pages/index';
@import 'components/modals/index';
@import './general/typography';

