.select--primary {
  label {
    .font(@alfa-color @font-large 22px 500);
  }

  .custom-select {
    cursor: pointer;

    &:before {
      content: " ";
      position: absolute;
      right: 36px;
      top: 50%;
      border: 10px solid transparent;
      border-top: 0;
      border-bottom-color: @thin-color;
      transform: translateY(-50%) rotate(180deg);
      .xs({
        right: 15px;
      });
    }
  }
}

