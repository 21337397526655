.controls {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-end;

  .md({
    flex: 1 1 auto;
    justify-content: center;
  });

  a {
    display: block;
    width: 27px;
    height: 27px;
  }
}

.controls__item {
  cursor: pointer;
}
