.career-text {
  text-align: center;
}

.career-text__caption {
  .font(@thin-color @font-large 22px 600, {
    text-transform: uppercase;
  });

  background-color: @brand-color;
  padding: 16px 15px;
  margin-bottom: 5px;
}

.career-text__field {
  .font(@beta-color @font-small 16px);

  textarea {
    font-family: @brand-family;
    background-color: @between-color;
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    box-sizing: border-box;
    padding: 16px 10px;
    text-align: center;
  }
}
