.certificate {
  width: ~"min(760px, 100%)";
  height: ~"min(537px, 537px)";
  //background: url("../../../../../images/general/certificate-min.jpg") top center / cover no-repeat @bg-global-color;
  text-align: center;
  //padding: 40px 0;
  position: relative;
  box-sizing: border-box;

  img {
    width: 100%;
  }

  h1 {
    color: @thin-color;
    margin-bottom: 40px;
  }
}

.certificate__title {
  margin-bottom: 41px;
  img {
    max-height: 112px;
  }
}

.certificate__data {
  width: ~"min(478px, 100%)";
  margin: 0 auto;
}

.certificate__data-field {
  .font(@thin-color @font-large 24px 500);

  border-bottom: 2px solid @thin-color;

  .last(not, {
    margin-bottom: 13px;
  });
}

.certificate__subtitle {
  .font(@thin-color 12px 15px 500, {
    letter-spacing: 0.11em;
  });

  position: absolute;
  bottom: calc(10% + 9px);
  right: calc(25% + 13px);
}
