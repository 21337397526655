.button--load {
  .font(@brand-color @font-large 21px 500);

  background-color: transparent;
  padding: 0;
  cursor: pointer;

  span {
    display: inline-block;
    vertical-align: top;
    margin-right: 13px;
  }
}

