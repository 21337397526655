.lesson {
  text-align: center;

  h4 {
    margin-bottom: 50px;
    text-align: center;
    color: @brand-color;
    text-transform: uppercase;
    font-weight: 600;
  }

  ul{
    margin: 0 0 30px;
    text-align: left;
  }
}

.lesson__main {
  display: grid;
  grid-template-columns: @lesson-width;
  grid-gap: 20px;
  text-align: left;
}

.lesson__list {
  margin-bottom: 20px;
}

.lesson__item {
  .font(@thin-color 16px 23px 600);
  word-break: break-word;
  display: block;
  position: relative;
  padding: 10px 30px 10px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: .7;
  }

  &:not(.inactive) {
    background-color: @brand-color;
  }

  &.inactive {
    background-color: #666666;
  }

  &.lesson__item--title{
    background-color: transparent;
    color: @brand-color;
    text-align: center;
  }

  .last(not, {
    margin-bottom: 20px;
  });

  span {
    position: absolute;

    &.icon--chat, &.icon--blocked {
      top: 6px;
      right: 6px;
    }
  }
}

.lesson__list-module {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  grid-gap: 20px;
  margin-bottom: 30px;
  padding-left: 160px;
  position: relative;

}

.lesson__list-lesson {
  display: grid;

  &.none{
    pointer-events: none;
  }

  &.lesson__list-lesson--module{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    div{
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 0 8px;
      background-color: transparent;
      color: @brand-color
    }
  }
}

.lesson__entry {

}

.lesson__video-main {
  position: relative;
  height: 680px;
  margin-bottom: 48px;
  //height: ~"min(680px, 30vh)"
}

.lesson__block {
  background-color: @thin-color;
  padding: 47px 52px;
  border: 1px solid @meta-color;
  box-sizing: border-box;

  .sm({
    padding: 47px 15px;
  });

  .last(not, {
    margin-bottom: 48px;
  });

  h3{
    margin-bottom: 30px;
  }

  h4 {
    --ls-brand-color: @beta-color;
  }

  .button--primary {
    min-width: 228px;

    &.inactive {
      filter: grayscale(100%);
      pointer-events: none;
    }
  }
}

.lesson__block-main {
  width: ~"min(811px, 100%)";
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  a{
    .font(@brand-color 18px 1);

    &:first-of-type{
      margin: 30px 0;
    }
  }
}

.lesson__block-thumbnail {
  position: relative;
  height: 527px;
}
