.previews-block {
  background-color: @thin-color;
  padding: 47px 52px;
  border: 1px solid @meta-color;
  box-sizing: border-box;

  .sm({
    padding: 47px 15px;
  });

  .last(not, {
    margin-bottom: 48px;
  });

  h4 {
    --ls-brand-color: @beta-color;
  }

  .button--load {
    margin-top: 49px;
  }
}

.previews-block__main {
  display: grid;
  grid-column-gap: 20px;
  grid-row-gap: 57px;

  .sm({
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  })
}

.previews-block__main-item {

}
