.career-table {
  text-align: center;
}

.career-table__caption {
  .font(@thin-color @font-large 22px 600, {
    text-transform: uppercase;
  });

  background-color: @brand-color;
  padding: 16px 15px;
  margin-bottom: 5px;
}

.career-table__head {

}

.career-table__body {

}

.career-table__row {
  display: flex;

  .last({
    .career-table__td {
      &:before {
        bottom: 5px;
      }
    }
  });
}

.career-table__th, .career-table__td {
  .font(@beta-color @font-small 16px);

  &.null{
    opacity: .5;
  }

  box-sizing: border-box;
  flex: 1 1 auto;
  width: calc(100%/4);

  .max(540px, {
    font-size: 8px;
  });
}

.career-table__th {
  font-weight: 600;
  background-color: @between-color;
  padding: 16px 10px;

  .max(540px, {
    padding: 16px 5px 16px 0;
  });

  .last(not, {
    box-shadow: -5px -5px 0 0 @thin-color inset;
  });
  .last({
    box-shadow: 0px -5px 0 0 @thin-color inset;
  });
}

.career-table__td {
  padding: 11px 10px;
  position: relative;

  &:before {
    content: " ";
    position: absolute;
    background-color: @between-color;
    right: 0;
    bottom: 2px;
    left: 0;
    height: 2px;
  }

  .last(not, {
    &:after {
      content: " ";
      position: absolute;
      background-color: @between-color;
      top: 3px;
      right: 1px;
      bottom: 10px;
      width: 2px;
    }
  });

  .select--born>div{
    width: auto;
    flex: 1 1 auto;
  }
}

.career-table--career {

  .career-table__row {
    &:last-child {
      .career-table__td {
        width: calc(100% / 1.5);
      }
    }
  }
  .career-table__th, .career-table__td {
    width: calc(100% / 3);
  }


}
