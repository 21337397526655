.button--large {
  .font(@white-lighter 28px 67px 600, {
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.065em;
  });

  padding: 0 52px;
  background-color: @brand-color;
  display: inline-block;
  min-width: 194px;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: @transition;

  .xs({
    font-size: 18px;
    padding: 0 15px;
  });

  &:hover{
    background-color: @danger;
  }
}

