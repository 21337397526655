.sign-in {
  .auth__entry {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 70px;

    .switch--primary {
      .xs({
        width: 100%;
        margin-bottom: 30px;
      });

      span {
        .font(@alfa-color @font-base);
      }

      a {
        .font(@brand-color @font-base 17px, {
          text-decoration: underline;
        });

        margin: 6px 0 0 30px;
        display: inline-block;
      }
    }
  }
}

.icon-holder, .sign-in {
  [class*="icon-"] {
    position: absolute;
    top: 45px;
    left: 31px;
    z-index: 1;
  }

  input {
    padding-left: 65px;
  }
}
