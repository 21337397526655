.contacts {
  text-align: center;

  h4 {
    margin-bottom: 50px;
    text-align: center;
    color: @brand-color;
    text-transform: uppercase;
    font-weight: 600;
  }
}

.contacts__block {
  background-color: @thin-color;
  padding: 47px 52px;
  border: 1px solid @meta-color;
  box-sizing: border-box;

  .sm({
    padding: 47px 15px;
  });

  .last(not, {
    margin-bottom: 48px;
  });

  h4 {
    --ls-brand-color: @beta-color;
  }

  label {
    .font(@alfa-color @font-base 21px);
    display: block;
    margin-bottom: 30px;
  }

  div {
    .last(not, {
      margin-bottom: 20px;
    });

    span, a {
      .font(@brand-color @font-large 21px 600);
      margin: 0 10px;
    }
  }

}
