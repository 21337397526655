.auth {
  width: ~"min(686px, 100%)";
  background-color: @thin-color;
  border: 1px solid @meta-color;
  box-sizing: border-box;
  margin: 0 auto;
  padding: @auth-pg;

  h4 {
    color: @brand-color;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 40px;

    a {
      .font(@brand-color @font-large 22px 600, {
        text-transform: capitalize;
      });

      position: absolute;
      top: @toggle-link-ps;
      right: 0;
    }
  }

  .input--primary, .select--born {
    .last(not, {
      margin-bottom: 33px;
    });
  }
}

.auth__data {
  position: relative;
}
