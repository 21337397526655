.store-buttons {
  display: flex;
  margin: 0 -5px;

  > a {
    display: block;
    width: 127px;
    height: 39px;
    border-radius: 4px;
    overflow: hidden;
    margin: 0 5px;
  }
}

.store-button__app {
  background: url("../../../../../images/general/app-store-min.png") center / cover no-repeat;
}

.store-button__google {
  background: url("../../../../../images/general/google-play-min.png") center / cover no-repeat;
}
